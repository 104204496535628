<template>
  <ValidationObserver ref="objects" v-slot="{ invalid }">
    <v-form @submit.prevent="submit">
      <ValidationProvider name="code" rules="required|min:6">
        <v-text-field
          id="code"
          label="Authentication code"
          name="code"
          placeholder="6 digit code"
          v-model="code"
          :error-messages="errors"
          slot-scope="{ errors }"
        />
      </ValidationProvider>
      <v-card-actions class="d-flex justify-space-between">
        <router-link to="/login" class="text-decoration-none text-uppercase"> Back </router-link>
        <v-btn type="submit" color="primary" :disabled="invalid">Verify</v-btn>
      </v-card-actions>
    </v-form>
  </ValidationObserver>
</template>

<script>
import AuthService from '@/services/AuthService';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max, min } from 'vee-validate/dist/rules';

extend('required', required);
extend('max', max);
extend('min', min);

export default {
  name: 'ChallangeForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      code: null,
      error: null,
      showcode: false
    };
  },
  methods: {
    async submit() {
      let payload = {};
      if (this.code.length === 6) {
        payload = {
          code: this.code
        };
      } else {
        payload = {
          recovery_code: this.code
        };
      }
      try {
        this.$store.dispatch('ui/loading', true);
        const confirmed = await AuthService.confirmChallenge(payload);
        if (confirmed) {
          const authUser = await this.$store.dispatch('auth/getAuthUser');
          this.$store.dispatch('ui/loading', false);
          if (authUser) {
            this.$store.dispatch('ui/snackbarSuccess', 'You have logged in succesfully.');
            this.$store.dispatch('auth/setGuest', { value: 'isNotGuest' });
            this.$router.push('/dashboard');
          } else {
            const error = Error();
            error.name = 'Fetch User';
            throw error;
          }
        }
      } catch (error) {
        this.$store.dispatch('ui/loading', false);
        this.$store.dispatch('ui/snackbarError', 'Unable to verify.');
      }
    }
  }
};
</script>
