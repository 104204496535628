import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"code","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"id":"code","label":"Authentication code","name":"code","placeholder":"6 digit code","error-messages":errors},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})}}],null,true)}),_c(VCardActions,{staticClass:"d-flex justify-space-between"},[_c('router-link',{staticClass:"text-decoration-none text-uppercase",attrs:{"to":"/login"}},[_vm._v(" Back ")]),_c(VBtn,{attrs:{"type":"submit","color":"primary","disabled":invalid}},[_vm._v("Verify")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }